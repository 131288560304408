
export const typography = {
  title1: {
    size: '40px',
    line: '44px',
  },
  title2: {
    size: '30px',
    line: '34px',
  },
  subtitle1: {
    size: '20px',
    line: '24px',
  },
  subtitle2: {
    size: '18px',
    line: '22px',
  },
  body1: {
    size: '15px',
    line: '19px',
  },
  body2: {
    size: '12px',
    line: '16px',
  },
}

export const footer = {
  height: '37px',
}

export const pallete = {
  blue: {
    main: '#2CABE1',
    muted: '#0b2b38',
  },
  pink: {
    main: '#E90D8C',
    muted: '#3a0323',
  },
  yellow: {
    main: '#FCEE23',
    muted: '#3f3b09',
  },
  gray: {
    main: 'rgba(0, 0, 0, 0.25)',
  },
}


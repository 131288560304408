import React from 'react'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import almeloPath from 'fonts/FV_Almelo.ttf'
import barlowPath from 'fonts/BarlowSemiCondensed-Medium.ttf'

const defaultFont = 'FVAlmelo'

const fonts = `
  @font-face {
    font-family: "FVAlmelo";
    src: url(${almeloPath}) format("truetype");
  }

  @font-face {
    font-family: "BarlowSemiCondensed-Medium";
    src: url(${barlowPath}) format("truetype");
  }
`

const bodyStyles = `
  html,
  body {
    height:100%; 
    min-height:100%;  
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: ${defaultFont}, sans-serif;
  }
`

const gatsbyStyles = `
  #___gatsby, 
  #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
  }
`

const gridStyles = `
  .container {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box; }
  .column,
  .columns {
    width: 100%;
    float: left;
    box-sizing: border-box; }

  @media (min-width: 400px) {
    .container {
      width: 85%;
      padding: 0; }
  }

@media (min-width: 550px) {
  .container {
    width: 80%; }
  .column,
  .columns {
    margin-left: 4%; }
  .column:first-child,
  .columns:first-child {
    margin-left: 0; }

  .one.column,
  .one.columns                    { width: 4.66666666667%; }
  .two.columns                    { width: 13.3333333333%; }
  .three.columns                  { width: 22%;            }
  .four.columns                   { width: 30.6666666667%; }
  .five.columns                   { width: 39.3333333333%; }
  .six.columns                    { width: 48%;            }
  .seven.columns                  { width: 56.6666666667%; }
  .eight.columns                  { width: 65.3333333333%; }
  .nine.columns                   { width: 74.0%;          }
  .ten.columns                    { width: 82.6666666667%; }
  .eleven.columns                 { width: 91.3333333333%; }
  .twelve.columns                 { width: 100%; margin-left: 0; }

  .one-third.column               { width: 30.6666666667%; }
  .two-thirds.column              { width: 65.3333333333%; }

  .one-half.column                { width: 48%; }

  }
`

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fonts}
  ${bodyStyles}
  ${gatsbyStyles}

  ${({ grid }) => grid ? gridStyles : ''}
`

export default function CssBaseline ({ grid, children }) {
  return (
    <React.Fragment>
      <GlobalStyle grid={grid} />
      { children }
    </React.Fragment>
  )
}
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { typography } from 'services/theme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
`

export const CenterText = styled.div`
  text-align: center;
`

export const Center = styled.div`
  margin: 0 auto;
`

export const TextContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
`

export const Typography = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ variant }) => typography[variant].size};
  line-height: ${({ variant }) => typography[variant].line};
  font-family: ${({ font }) => font === 'secondary' ? 'BarlowSemiCondensed-Medium' : 'FVAlmelo' };
`

export const Spacer = styled.div`
  width: 100%;
  height: ${({ size }) => size}rem;
`

export const Anchor = styled.a`
  outline: none;
  text-decoration: none;
`

export const Link = styled(GatsbyLink)`
  outline: none;
  text-decoration: none;
`
